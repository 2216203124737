import React, {useEffect, useState} from 'react';
import { createClient } from 'contentful';

const Article = ({id, contentType, slugBase, limit}) => {

  const [allEntries, setEntries] = useState([]);
  const entryId = id;
  const dateLocale = "en-GB";
  const dateOptions = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };

  const client = createClient({
    space: 'qzvdhv6dk1nu',
    environment: 'master',
    accessToken: 'yF08NDjz2PAvDXY7SsJ57o2vkwpxT1zOjunvfbNOx_4'
  })

  useEffect (() => {
    const getEntries = async () => {
      try {
        await client.getEntries({
          content_type: contentType,
          limit: limit
        }).then((entries) => {
          const filtered = entries?.items?.filter(entry => entry.sys.id !== entryId);
          setEntries(filtered);
        })
      } catch(error) {
        console.log(error);
      }

    }
    getEntries()

  }, [])

  if (!allEntries) {
    return "Loading...";
  }

  return (
    <div className="container pt-1 row gx-1 gx-sm-4 gy-4 flex-wrap align-items-stretch px-0">
      {allEntries?.map((entry) =>
      <div className="single-entry single-guide col-12 col-sm-6 col-md-4 col-lg-3" key={entry?.sys?.id}>
        <div className="d-flex flex-column flex-wrap bg-light">
          <div className="img-wrap d-flex ratio-3x2 position-relative">
            <img 
            src={entry?.fields?.featuredImage?.fields?.file?.url + '?h=200'} 
            className="object-fit-cover object-position-center"
            width={300}
            height={200}
            alt={entry?.fields?.title}></img>
          </div>
 
          <div className="col-12 d-flex flex-column p-3">
            {/* <div className="d-flex">
            {entry?.metadata?.tags.map((tag) =>
              <h6 className={tag?.sys?.id + ' mb-2 me-2 fs-8 fw-normal d-flex align-items-baseline category tag'} key={tag?.sys?.id}>
                <div className="indicator"></div>{tag?.sys?.id}
              </h6>
            )}
            </div> */}
            <h2 className="fs-6 mb-3 min-h-60px">{entry?.fields?.title}</h2>
            <div className="meta mb-3 fs-8">
              <div className="author">von: <a href={'autor/' + entry?.fields?.author?.fields?.slug} className="fw-bold">{entry?.fields?.author?.fields?.name}</a></div>
              <div className="date-created">{new Date(entry?.sys?.createdAt).toLocaleDateString(dateLocale, dateOptions)}</div>
            </div>
            <a href={`${slugBase + entry?.fields?.slug}`}
            id={entry?.sys?.id}
            className="fs-6 fw-bold py-2 px-4 text-dark bg-secondary text-decoration-none text-center">mehr lesen</a>
           </div>
          </div>
      </div>
      )}
    </div>
  )
}

export default Article
