import React, {useState} from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { Rating, ThinStar } from '@smastrom/react-rating'
import iNoCoin from '../../../assets/img/icons/no-coin.svg';

const Blacklist = ({blacklisted}) => {

  const customStyles = {
    itemShapes: ThinStar,
    activeFillColor: '#FF6F82',
    inactiveFillColor: '#A5A2B2',
  };

  function getRating(rating) {
    switch (rating) {
      case 1:
        return 'Poor';
      case 2:
        return 'Nothing special';
      case 3:
        return 'Average';
      case 4:
        return 'Very good';
      case 5:
        return 'Excellent';
      default:
        return 'None';
    }
  }
  const [rating, setRating] = useState(0);

  const renderOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <>{children}</>,
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        // target the contentType of the EMBEDDED_ENTRY to display as you need
        if (node?.data?.target?.sys?.contentType?.sys?.id === "casino") {
          return (
            <SplideSlide>
              <div className="p-4 bg-light">
                <a href={'/casinos/' + node?.data?.target?.fields?.slug} className="bg-ash d-flex flex-column p-4 align-items-center justify-content-center slide-img">
                <img
                src={`https://${node?.data?.target?.fields?.featuredImage?.fields?.file?.url + '?h=80'}`}
                width="auto"
                alt={node?.data?.target?.fields?.title}
                className="flex-grow-0"
                />
                </a>
                <h3 className="fs-6 my-3">{node?.data?.target?.fields?.title}</h3>
                <div className="rating-value d-flex align-items-center">
                  <Rating className="rating-stars"
                    style={{ maxWidth: 86 }}
                    value={node?.data?.target?.fields?.rating}
                    itemStyles={customStyles}
                    readOnly
                  />
                  <div className="rating-number fw-bold ms-1"><span className="fs-5">{node?.data?.target?.fields?.rating}</span>/5</div>
                </div>

                <div className="d-flex mt-2">
                  <img src={iNoCoin} alt=""></img>
                  <span className="ms-2">Keine gültige Lizenzierung</span>
                </div>
              </div>
              </SplideSlide>
          );
        }
      }
    },
  };

  return (
    <>
    <Splide
      className="blacklist-slider slider controls-top-right"
      options={ { 
        rewind: false, 
        type:'slider', 
        perPage: 2,
        gap: 20,
        drag:true,
        perMove: 1,
        pagination: false,
        mediaQuery: 'max',
        breakpoints: {
          992: {
            perPage: 1,
          },
        }
      } } 
      aria-label="Blacklisted Casinos">
        {documentToReactComponents(blacklisted, renderOptions)}      
    </Splide>
    </>
  );
}

export default Blacklist;