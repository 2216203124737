import './assets/styles/style.css';
import { HelmetProvider } from 'react-helmet-async';
import Header from './content/Components/Header';
import Home from './content/Pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './content/Components/Footer';
import ScrollToTop from "react-scroll-to-top";
import { lazy, Suspense } from 'react';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-NZC6R6C'
}

TagManager.initialize(tagManagerArgs);

const helmetContext = {};
const Casino = lazy(() => import('./content/Pages/Casino' ));
const Guides = lazy(() => import('./content/Pages/Ratgeber' ));
const Guide = lazy(() => import('./content/Pages/Guide' ));
const AllNews = lazy(() => import('./content/Pages/AllNews' ));
const News = lazy(() => import('./content/Pages/News' ));
const Author = lazy(() => import('./content/Pages/Author' ));
const Authors = lazy(() => import('./content/Pages/Autoren' ));
const Default = lazy(() => import('./content/Pages/Default' ));

function App() {
  return (
    <HelmetProvider context={helmetContext}>
    <Router>
      <Header></Header>
      <Routes>
        <Route exact caseSensitive path="/" element={<Home />} />
        <Route exact caseSensitive path="/ratgeber" element={<Suspense fallback={<></>}> <Guides /> </Suspense>}/>
        <Route exact caseSensitive path="/ratgeber/:slug" element={<Suspense fallback={<></>}> <Guide /> </Suspense>}/>
        <Route exact caseSensitive path="/news" element={<Suspense fallback={<></>}> <AllNews /> </Suspense>}/>
        <Route exact caseSensitive path="/news/:slug" element={<Suspense fallback={<></>}> <News /> </Suspense>}/>
        <Route exact caseSensitive path="/autoren" element={<Suspense fallback={<></>}> <Authors /> </Suspense>}/>
        <Route exact caseSensitive path="/autor/:slug" element={<Suspense fallback={<></>}> <Author /> </Suspense>}/>
        <Route exact caseSensitive path="/casinos/:slug" element={<Suspense fallback={<></>}> <Casino /> </Suspense>} />
        <Route exact caseSensitive path="/:slug" element={<Suspense fallback={<></>}> <Default /> </Suspense>}/>
      </Routes>
      <Footer></Footer>
      <ScrollToTop smooth color="#36B37E" />
    </Router>
    </HelmetProvider>
  );
}

export default App;
